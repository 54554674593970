import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14d81555"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "home"
}
const _hoisted_2 = { class: "hero__block" }
const _hoisted_3 = { class: "hero__content" }
const _hoisted_4 = { class: "hero__text-container" }
const _hoisted_5 = {
  key: 0,
  class: "projects-container",
  ref: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SendUs = _resolveComponent("SendUs")!
  const _component_ProjectCard = _resolveComponent("ProjectCard")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_ctx.home)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", {
            class: _normalizeClass(["h1 hero__title", { hero__title_rebase: _ctx.isRebase }])
          }, _toDisplayString(_ctx.home.offer), 3),
          _createVNode(_component_SendUs, {
            class: "hero__send",
            href: _ctx.home.social_networks.telegram,
            target: "_blank"
          }, null, 8, ["href"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                return _createElementVNode("p", {
                  class: "hero__text",
                  key: `key-${i}`
                }, _toDisplayString(_ctx.home.description), 1)
              }), 64))
            ])
          ])
        ]),
        (_ctx.projects && !!_ctx.projects.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (item) => {
                return (_openBlock(), _createBlock(_component_ProjectCard, {
                  project: item,
                  key: item.title
                }, null, 8, ["project"]))
              }), 128))
            ], 512))
          : _createCommentVNode("", true),
        _createVNode(_component_Loader, { "is-loading": _ctx.loading }, null, 8, ["is-loading"])
      ]))
    : _createCommentVNode("", true)
}