
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import ProjectCard from '@/components/ProjectCard.vue'
import { fetchProjects, LIMIT_PROJECTS } from '@/api/fetchProjects'
import { useStore } from 'vuex'
import Loader from '@/components/Loader.vue'
import SendUs from '@/components/SendUs.vue'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  name: 'Home',
  components: {
    SendUs,
    Loader,
    ProjectCard
  },
  setup() {
    const store = useStore()
    const projects = computed(() => store.state.projects)
    const config = WEBSITE_CONFIG()

    const page = ref(1)
    const end = ref(false)
    const loading = ref(false)

    const loadProjects = async () => {
      loading.value = true
      const data = await fetchProjects(page.value)
      store.commit('addProjectsData', [...projects.value, ...data])
      page.value += 1
      end.value = !data.length || data.length < LIMIT_PROJECTS
      loading.value = false
    }

    const scrollHandle = () => {
      const isBottom = window.innerHeight + window.scrollY === document.documentElement.offsetHeight
      if (isBottom && !loading.value && !end.value) {
        loadProjects()
      }

      if (isBottom) {
        document.body.className = 'invert'
      } else {
        document.body.className = ''
      }
    }

    onBeforeMount(async () => {
      await store.dispatch('FETCH_PROJECTS')
      if (projects.value) {
        window.addEventListener('scroll', scrollHandle)
        window.scrollTo(0, 0)
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollHandle)
    })

    return {
      projects,
      home: computed(() => store.state.home),
      loading,
      isRebase: config.type === 'rebase'
    }
  }
})
