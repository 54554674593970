<template>
  <a href="/">
    <span>Contacts us</span>
    <svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7617 9.33828L10.4312 0.372265C10.352 0.303906 10.2508 0.265625 10.1441 0.265625H7.72422C7.52187 0.265625 7.42891 0.517188 7.58203 0.648438L17.1578 8.96094H1.15625C1.03594 8.96094 0.9375 9.05937 0.9375 9.17969V10.8203C0.9375 10.9406 1.03594 11.0391 1.15625 11.0391H17.1551L7.5793 19.3516C7.42617 19.4855 7.51914 19.7344 7.72148 19.7344H10.2234C10.2754 19.7344 10.3273 19.7152 10.3656 19.6797L20.7617 10.6617C20.8564 10.5794 20.9323 10.4777 20.9843 10.3636C21.0363 10.2494 21.0632 10.1254 21.0632 10C21.0632 9.87456 21.0363 9.75057 20.9843 9.63642C20.9323 9.52226 20.8564 9.4206 20.7617 9.33828Z"
      />
    </svg>
  </a>
</template>

<style scoped>
@keyframes flipover {
  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  49% {
    transform: translateY(0.6em);
    opacity: 0;
  }
  50% {
    transform: translateY(-0.6em);
    opacity: 0;
  }
}

a {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 30px;
  border-radius: 100px;
  background-color: var(--black);
  color: var(--white);
  font-weight: 600;
  line-height: 1.6;
  transition: background-color 0.2s ease-out, color 0.1s 0.05s linear;
  border: 2px solid var(--black);
}

span {
  margin-right: 12px;
  display: block;
}

svg {
  fill: var(--white);
  width: 20px;
  height: auto;
  transition: fill 0.1s 0.05s linear;
}

a:hover,
a:focus {
  background-color: var(--white);
  color: var(--black);
}

a:hover span,
a:focus span {
  animation: 0.5s flipover cubic-bezier(0.3, 0.86, 0.36, 0.95);
}

a:hover svg,
a:focus svg {
  fill: var(--black);
  animation: 0.5s flipover cubic-bezier(0.3, 0.86, 0.36, 0.95);
}

@media screen and (max-width: 640px) {
  a {
    font-size: 16px;
    padding: 10px 24px;
  }
}
</style>
