
import { Project } from '@/types/Project'
import { computed, defineComponent, PropType, ref } from 'vue'
import { getCategories } from '@/assets/ts/getCategories'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  name: 'ProjectCard',
  props: {
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    small: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const config = WEBSITE_CONFIG()
    const categories = ref('')
    const image = computed(
      () => `${config.url}${props.project.attributes.cover.data.attributes.url}`
    )
    categories.value = getCategories(props.project.attributes.categories.data)

    return {
      image,
      categories
    }
  }
})
