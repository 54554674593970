import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4a646c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "card__type" }
const _hoisted_3 = { class: "card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/project/${_ctx.project.id}`,
    class: "card-block"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["card__image", { card__image_small: _ctx.small }])
      }, [
        _createElementVNode("img", {
          src: _ctx.image,
          alt: _ctx.project.attributes.cover.data.attributes.alternativeText
        }, null, 8, _hoisted_1)
      ], 2),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.categories), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.project.attributes.title), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}